import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../../../shared/SvgIcon'

const OutageBanner = (props) => {
  const { message, isAvailable } = props
  const [isBanner, setIsBanner] = useState('')
  useEffect(() => {
    const key = 'outage_banner'

    let banner = sessionStorage.getItem(key)

    if (!banner) {
      banner = isAvailable
      sessionStorage.setItem(key, JSON.stringify(banner))
    }

    setIsBanner(banner)
  })
  const handleOnClick = () => {
    sessionStorage.setItem('outage_banner', JSON.stringify(false))
    setIsBanner('')
  }

  if (isBanner !== 'true' || !message) return null

  return (
    <div
      className="gap-x-primary grid grid-cols-4 bg-sky-150 p-5 lg:grid-cols-12 lg:pr-5"
      data-testid="outage-banner"
    >
      <p className="col-span-3 text-l font-normal lg:col-span-9 lg:col-start-2">
        {message}
      </p>
      <button
        type="button"
        aria-label="close-button"
        className="col-start-4 mr-0 h-fit justify-self-end lg:col-start-12 lg:my-auto"
        onClick={handleOnClick}
      >
        <SvgIcon name="close" className="h-4 w-4 fill-gray-500" />
      </button>
    </div>
  )
}

OutageBanner.defaultProps = {
  message: '',
  isAvailable: false,
}

OutageBanner.propTypes = {
  message: PropTypes.string,
  isAvailable: PropTypes.bool,
}

export default OutageBanner
